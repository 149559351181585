import React, { Component } from 'react';
import PropTypes from 'prop-types';  
import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    Button,
    Typography,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from 'moment';

const styles = theme => ({
    container: {
        display: 'grid',
        flexWrap: 'wrap',   
        minWidth: 250,     
    },    
    textField: {},    
});

class Archive extends Component {
    state = {
        emitted_from: new Date(moment().startOf('month').format()),
        emitted_to: new Date(moment().endOf('month').format()),
        email_to: '',
      };
    
      valid = () => {
          return !(this.state.email_to === '')
      }

      handleSubmit = () => {
        const { 
            emitted_from, 
            emitted_to,
        } = this.state;
                  
          this.props.handleSubmit(this.props.wizard, {
            ...this.state,
            emitted_from: emitted_from instanceof Date ? emitted_from.toISOString() : emitted_from.format(),
            emitted_to: emitted_to instanceof Date ? emitted_to.toISOString() : emitted_to.format(),
          }, null)
      }
    
      render() {
        const { 
            title,
            classes,
            loading,
        } = this.props;
        const { 
            emitted_from, 
            emitted_to,
            email_to,
        } = this.state;
    
        return (
            <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="headline" gutterBottom>{title}</Typography>
                <MuiPickersUtilsProvider utils={MomentUtils} locale='it' moment={moment}>
                    <DatePicker
                        margin="normal"
                        label="Data Emissione da"
                        value={emitted_from}
                        cancelLabel="Annulla"
                        format='DD/MM/YYYY'
                        onChange={(date) => {
                            this.setState({            	 		   
                                ...this.state,
                                emitted_from: date,
                            })              	 
                        }}
                    />
                    <DatePicker
                        margin="normal"
                        label="Data Emissione a"
                        value={emitted_to}
                        cancelLabel="Annulla"
                        format='DD/MM/YYYY'
                        onChange={(date) => {
                            this.setState({            	 		   
                                ...this.state,
                                emitted_to: date,
                            })              	 
                        }}
                    />              
                </MuiPickersUtilsProvider>
                <TextField
                    label="Destinatari"
                    helperText="Inserisci i destinatari separati da una virgola."
                    className={classes.textField}
                    value={email_to}
                    onChange={(event) => {
                        this.setState({
                            ...this.state,
                            email_to: event.target.value,
                        }) 
                    }}
                    margin="normal"
                />    
                <Button
                    variant="contained" 
                    fullWidth={true}  
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={!this.valid() && !loading}>
                    Invia
                </Button>                  
            </form>    
        );
      }

}

Archive.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
};
    
Archive.defaultProps = {
    title: 'Archivio Fatture',
};

export default withStyles(styles)(Archive);
