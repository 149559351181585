import React from 'react';
import { 
    Filter, 
    TextInput, 
    List, 
    Datagrid, 
    TextField, 
    ReferenceField,
    FunctionField,
    Edit,
    SimpleForm,
    AutocompleteInput,
    Create,
    ReferenceInput,
} from 'react-admin';
import { 
    nameRenderer,
    selectCountry,
    DefaultBulkActionButtons,
    DefaultPagination,
} from './utils';
import PlaceIcon from '@material-ui/icons/Place';

export { PlaceIcon as ActivationIcon };

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const ActivationCreate = (props) => (
    <Create 
      {...props} 
      title="Nuova Attivazione"   
    >  
        <SimpleForm defaultValue={{ country: 'Italia' }}>
            <ReferenceInput label="Cliente" source="customer-id" reference="customers" allowEmpty>
                <AutocompleteInput optionText={nameRenderer} />
            </ReferenceInput>      
            <TextInput source="name" label="Nome" /> 
            <TextInput source="surname" label="Cognome"/> 
            <AutocompleteInput source="country" label="Paese" choices={selectCountry}/>
            <TextInput source="street-address" label="Indirizzo"/>
            <TextInput source="building-number" label="Nr. civico"/>
            <TextInput source="city" label="Città"/>
            <TextInput source="province" label="Provincia"/>
            <TextInput source="postcode" label="CAP"/>
      </SimpleForm>
    </Create>
);

export const ActivationEdit = (props) => (
    <Edit
      {...props}
      /* disable the app title change when shown */
      title=" " 
    >
      <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`activation_edit_${props.id}`}        
      >
            <TextInput source="name" label="Nome" /> 
            <TextInput source="surname" label="Cognome"/> 
            <AutocompleteInput source="country" label="Paese" choices={selectCountry}/>
            <TextInput source="street-address" label="Indirizzo"/>
            <TextInput source="building-number" label="Nr. civico"/>
            <TextInput source="city" label="Città"/>
            <TextInput source="province" label="Provincia"/>
            <TextInput source="postcode" label="CAP"/>
      </SimpleForm>
    </Edit>
  );


export const ActivationList = (props) => (
    <List 
        {...props}
        title="Attivazioni" 
        bulkActionButtons={<DefaultBulkActionButtons/>}
        perPage={25}
        pagination={<DefaultPagination />}
        filters={< Filters />}>
        <Datagrid expand={<ActivationEdit />}>   
            <ReferenceField 
                label="Cliente" 
                source="customer-id" 
                reference="customers"
                linkType={false}
                sortBy="customer.surname">
                <FunctionField label="Nome" render={record => `${record.name} ${record.surname}`} />
            </ReferenceField>               
            <TextField source="name" label="Nome"/>
            <TextField source="surname" label="Cognome"/>
            <TextField source="country" label="Paese"/>
            <TextField source="city" label="Città"/>
            <TextField source="province" label="Provincia"/>
            <TextField source="street-address" label="Indirizzo"/>
            <TextField source="building-number" label="Nr. civico"/>                
            <TextField source="postcode" label="CAP"/>              
        </Datagrid>
    </List>
);