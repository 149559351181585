import React, { Component } from 'react';
import PropTypes from 'prop-types';    
import {
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Title } from 'react-admin';
import Archive from './dashboard/Archive';
import Send from './dashboard/Send';
import Wizard from './Wizard';
import SetInvoiceNumber from './dashboard/SetInvoiceNumber';

const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: '1em',
    },    
    card: {
        minWidth: 275,
    },    
});

class Dashboard extends Component {

    render() {

        const {
            title,
        } = this.props;

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Title title={title} />    
                <Grid container spacing={16}>  
                    <Grid item xs>
                        <Wizard>
                            <SetInvoiceNumber wizard='set_invoice_number'/>
                        </Wizard>
                    </Grid>                                   
                    <Grid item xs>                        
                        <Wizard>
                            <Archive title='Archivio Fatture' wizard='archive_invoices'/>    
                        </Wizard>
                    </Grid>
                    <Grid item xs>
                        <Wizard>
                            <Archive title='Esporta XML per SDD' wizard='export_xml_sdd'/>
                        </Wizard>
                    </Grid>                  
                    <Grid item xs>
                        <Wizard>
                            <Archive title='Esporta XML per FA' wizard='fa_email_zip'/>
                        </Wizard>
                    </Grid>                                          
                    <Grid item xs>
                        <Wizard>
                            <Send title='Invia Fatture' wizard='send_invoices'/>
                        </Wizard>
                    </Grid>                           
                </Grid>
            </div>
        );
    }

}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};
    
Dashboard.defaultProps = {
    title: 'Cruscotto',
};

export default withStyles(styles)(Dashboard);