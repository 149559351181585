import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import { addField } from 'react-admin';
import { get } from 'lodash';

const styles = theme => ({
    textField: {
        marginTop: 16,
        marginBottom: 8,
        minWidth: 256,
    },    
});

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
    <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
            onChange({
                target: {
                    value: values.value,
                    },
                });
        }}
        thousandSeparator="."
        decimalSeparator=","
        suffix=" €"
    />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};


class CurrencyInput extends Component {

    constructor(props) {
        super(props);
        const rxValue = this.props.record[this.props.id] || get(this.props.record, this.props.source, 0);
        this.state = {
            rawValue: parseInt(rxValue),
        }
      }    
      
    handleBlur = event => {
        const numericValue = this.notifyEvent(event.target.value);
        this.props.onBlur && this.props.onBlur(numericValue);
        this.props.input && this.props.input.onBlur(numericValue);
    };

    handleFocus = event => {
        this.props.onFocus && this.props.onFocus(event);
        this.props.input && this.props.input.onFocus(event);
    };

    handleChange = event => {
        const numericValue = this.notifyEvent(event.target.value);
        this.props.onChange(numericValue);
        this.props.input && this.props.input.onChange(numericValue);
    };    

    notifyEvent = value => {
        const numericValue = this.stringToCents(this.parseRawValue(value));
        this.setState({
            rawValue: numericValue,
        })
        return numericValue;
    }

    parseRawValue = (displayedValue) => {
        const value = displayedValue.replace(/[^0-9]/g, '');
        return parseFloat(value);
    }

    formatRawValue = (rawValue) => {

        const {
            precision, 
            unit,
            delimiter,
            separator,
        } = this.props;        

        if (rawValue === undefined) { return `0.0 ${unit}`; }

        const minChars = '0'.length + precision;
        let result = `${rawValue}`;

        if (result.length < minChars) {
            const numbers = minChars - result.length;
            const leftZeroPad = String(0).repeat(numbers);
            result = `${leftZeroPad}${result}`;
        }

        let beforeSeparator = result.slice(0, result.length - precision);
        const afterSeparator = result.slice(result.length - precision);

        if (beforeSeparator.length > 3) {
            const chars = beforeSeparator.split('').reverse();
            let withDots = '';

            for (let i = chars.length - 1; i >= 0; i--) {
                const char = chars[i];
                const dot = i % 3 === 0 ? delimiter : '';
                withDots = `${withDots}${char}${dot}`;
            }

            withDots = withDots.substring(0, withDots.length - 1);
            beforeSeparator = withDots;
        }

        result = beforeSeparator + separator + afterSeparator;

        if (unit) { result = `${result} ${unit} `; }

        return result;
    }

    stringToCents = (val) => {
        const {
            precision, 
            //cents,
        } = this.props;
        
        if (val === undefined) { return 0; }
        const raw = val.toString();
        if (isNaN(parseFloat(raw))) { return 0; }
        if (!raw.length) { return parseFloat(raw); }
        if (precision >= raw.length) { return parseFloat(raw); }
        const prefix = raw.slice(0, raw.length - precision);
        const sufix = raw.slice(raw.length - precision, raw.length);
        return Math.ceil(parseFloat(`${prefix}.${sufix}`)*100)
    }

    render() {
    	
        const {
            classes,
            className,
            input,
            isRequired,
            label,
            meta,
            options,
            source,
            step,
            resource,
            basePath,

            ...rest
        } = this.props;
        // if (typeof meta === 'undefined') {
        //     throw new Error(
        //         "The NumberInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
        //     );
        // }
        //const { touched, error } = meta;
        
       

        return (
            <FormControl fullWidth className={classes.textField}>
            <InputLabel htmlFor="adornment-amount">{label}</InputLabel>
            <Input
                // error={!!(touched && error)}
                // helperText={touched && error}             
                className={className}
                // label={
                //     <FieldTitle
                //         label={label}
                //         source={source}
                //         resource={resource}
                //         isRequired={isRequired}
                //     />
                // }                
                {...options}
                {...rest}
                {...input}         
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                //value={this.state.formattedValue}
                value={this.formatRawValue(this.state.rawValue)}
                // InputProps={{
                //     inputComponent: NumberFormatCustom,
                // }}                                
                startAdornment={<InputAdornment position="start">€</InputAdornment>}                
                // dataPolitespace
                // dataGrouplength={2}
                // dataDelimiter=","
                // dataReverse
             />
            </FormControl>
        );
    }

}

CurrencyInput.propTypes = {
    id: PropTypes.string,
    delimiter: PropTypes.string,
    onChange: PropTypes.func,
    precision: PropTypes.number,
    separator: PropTypes.string,
    unit: PropTypes.string,
    value: PropTypes.number,
    converter: PropTypes.func,
    cents: PropTypes.string,
};

CurrencyInput.defaultProps = {
    value: 0,
    precision: 2,
    separator: ',',
    delimiter: '.',
    //unit: '€',
    unit: null,
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    cents: "true",
};

export const CurrencyInputWithField = addField(CurrencyInput);

export default withStyles(styles)(CurrencyInputWithField);

export const CurrencyInputWithStyle = withStyles(styles)(CurrencyInput);