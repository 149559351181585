import React, { Component } from 'react';
import PropTypes from 'prop-types';
import inflection from 'inflection';
import Button from '@material-ui/core/Button';
import { fetchUtils } from 'react-admin';
import { baseApiUrl } from '../App';
import { setRequestSessionHeaders } from '../authClient';

class SendEmail extends Component {

    state = {
        loading: false,
    }

    handleSend = () => {
        this.setState({loading: true});

        const {record, resource, endpoint} = this.props;
        const params = record === undefined ? {} : { id: record.id };
        const url = endpoint === undefined ? `email_${inflection.singularize(resource)}` : endpoint
        const headers = new Headers();
        headers.set('Accept', 'application/vnd.api+json');
        setRequestSessionHeaders(headers);
        fetch(`${baseApiUrl}/v1/mailer/${url}?${fetchUtils.queryParameters(params)}`, {
                method: 'POST',
                headers: headers
            })
            .then(response => response.text().then(text => ({status: response.status, statusText: response.statusText, headers: response.headers, body: text})))
            .then(({status, statusText, headers, body}) => {
                let json;
                try {
                    json = JSON.parse(body);
                } catch (e) {}
                if (status < 200 || status >= 300) {
                    this.setState({
                        loading: false,
                        result: json
                            .errors
                            .map(error => error.detail)
                            .join(", ")
                    })
                } else {
                    this.setState({loading: false})
                }
            });
    }

    render() {

        const { label } = this.props;

        return <Button
                    variant="outlined" 
                    color="primary"
                    onClick={this.handleSend}
                    disabled={this.state.loading}
                >
                {this.state.loading
                    ? 'Inviando..'
                    : label}
                </Button>

    }
}

SendEmail.propTypes = {
    record: PropTypes.object,
    resource: PropTypes.string,
    showNotification: PropTypes.func,
    label: PropTypes.string,
};

SendEmail.defaultProps = {
    label: 'Email'
}

export default SendEmail;