import React, { Component } from 'react';
import PropTypes from 'prop-types';    
import {
    Card, 
    CardActions, 
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    MenuItem,  
    Select,
    LinearProgress,
    List, 
    ListItem, 
    ListItemText,
    Avatar,
    InputLabel,
    FormControl, 
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
    EuroSymbol,
} from '@material-ui/icons';
import {
    Title, 
    Pagination
} from 'react-admin';
import { fetchUtils } from 'react-admin';
import { baseApiUrl } from './App';
import { setRequestSessionHeaders } from './authClient';
import { get } from 'lodash';
import {
    CustomerIcon,
    InvoiceIcon,
} from './resources';
import { capitalize } from 'lodash';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
export { TrendingUpIcon as ReportIcon };
//import { CSVLink, CSVDownload } from 'react-csv';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: 24,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },    
    aggregates: {
  	  display: 'flex',
  	  justifyContent: 'space-between',
  	  margin: '0 240px',
    },
});

class Report extends Component {

    state = {
        title: 'Reports',
        kind: 'consumer',
        bimester: 1,
        year: new Date().getFullYear(),
        isLoading: true,
        data: [],
        page: 1,
        perPage: 25,
    };

    componentDidMount() {
        const {kind, page, bimester, year} = this.state;
        this._fetch(bimester, year, kind, page)
    };

    componentWillUpdate(nextProps, nextState) {
        const {kind, page, bimester, year} = this.state;
        if (nextState.kind !== kind) {
            this.setState({isLoading: true});
            this._fetch(bimester, year, nextState.kind, page)
        };
        if (nextState.bimester !== bimester) {
            this.setState({isLoading: true});
            this._fetch(nextState.bimester, year, kind, page)            
        }
        if (nextState.year !== year) {
            this.setState({isLoading: true});
            this._fetch(bimester, nextState.year, kind, page)            
        }        
    };

    setPage = page => {
        const { kind, bimester, year } = this.state;
        this._fetch(bimester, year, kind, page);
    }

    _fetch(bimester, year, kind, page) {        
        const params = {
            bimester: bimester,
            page: page,
            year: year,
            kind: kind,
            per_page: this.state.perPage,
        }
        const requestHeaders = new Headers();
        requestHeaders.set('Accept', 'application/vnd.api+json');
        setRequestSessionHeaders(requestHeaders);
        fetch(`${baseApiUrl}/v1/reports?${fetchUtils.queryParameters(params)}`, {
                method: 'GET',
                headers: requestHeaders
            })
            .then(response => response.text().then(text => ({
                status: response.status, 
                statusText: response.statusText, 
                headers: response.headers, 
                body: text})))
            .then(({status, statusText, headers, body}) => {
                let json;
                try { json = JSON.parse(body); } 
                catch (e) {}
                if (status < 200 || status >= 300) {
                    this.setState({
                        loading: false,
                        result: "Your request could not be processed, please try again."
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        data: json.data,
                        page: json.meta.pagination.page,
                        total: json.meta.pagination.total,
                        aggregations: json.meta.aggregations
                    })
                }
            });
    }

    render() {

        const {
            title,
            kind,
            bimester,
            year,
            data,
            page,
            total,
            aggregations,
            isLoading,
            perPage,
        } = this.state;

        const { classes } = this.props;

        const headerColumns = [
            'Cliente',
            'Codice',
            'Attivazione',
            'Contratto',
            'Nr. Fattura',
            'Netto',
            'Sconto',
            'Imponibile',
        ];

        const bimesterChoices = [
            {id: 1, name: 'Gennaio/Febbraio'},
            {id: 2, name: 'Marzo/Aprile'},
            {id: 3, name: 'Maggio/Giugno'},
            {id: 4, name: 'Luglio/Agosto'},
            {id: 5, name: 'Settembre/Ottobre'},
            {id: 6, name: 'Novembre/Dicembre'},
        ];
        
        const yearChoices = [2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025];   
        
        const kindChoices = ['consumer', 'business'];

        return (
            <Card>
                <CardActions>
                </CardActions>  
                <Title title={title} />     
                <form className={classes.root} autoComplete="off">
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="kind">Tipo</InputLabel>
                        <Select
                            value={kind}              
                            onChange={(event) => { this.setState({kind: event.target.value}) }}>
                            {kindChoices.map(kindChoice => {
                                return <MenuItem
                                            key={kindChoice}
                                            value={kindChoice}>
                                                {capitalize(kindChoice)}
                                        </MenuItem>
                            })}
                        </Select> 
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="bimester">Bimestre</InputLabel>
                        <Select
                            value={bimester}
                            onChange={(event) => { this.setState({bimester: event.target.value}) }}>
                            {bimesterChoices.map(bimesterChoice => {
                                return <MenuItem
                                            key={bimesterChoice.id}
                                            value={bimesterChoice.id}>
                                                {bimesterChoice.name}
                                        </MenuItem>
                            })}
                        </Select>
                    </FormControl>     
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="year">Anno</InputLabel>
                        <Select
                            value={year}
                            onChange={(event) => { this.setState({year: event.target.value}) }}>
                            {yearChoices.map(yearChoice => {
                                return <MenuItem
                                            key={yearChoice}
                                            value={yearChoice}>
                                                {yearChoice}
                                        </MenuItem>
                            })}
                        </Select>  
                    </FormControl>                                    
                </form>    
                <CardContent>
                    {isLoading
                        ? <LinearProgress mode="indeterminate"/>
                        : total > 0
                            ? <div>    
                                    <List className={classes.aggregates}>                                
                                        <ListItem>
                                            <Avatar>
                                                <CustomerIcon />
                                            </Avatar>                                            
                                            <ListItemText 
                                                primary={`${get(aggregations, "customers_count")}`} 
                                                secondary="Clienti">
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <Avatar>
                                                <InvoiceIcon />
                                            </Avatar>                                             
                                            <ListItemText 
                                                primary={`${get(aggregations, "invoices_count")}`} 
                                                secondary="Fatture" >
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <Avatar>
                                                <EuroSymbol />
                                            </Avatar>                                              
                                            <ListItemText 
                                                primary={`${get(aggregations, "net_cents_total_display")}`} 
                                                secondary="Netto">
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <Avatar>
                                                <EuroSymbol />
                                            </Avatar>                                              
                                            <ListItemText 
                                                primary={`${get(aggregations, "discount_cents_total_display")}`} 
                                                secondary="Sconto" >
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <Avatar>
                                                <EuroSymbol />
                                            </Avatar>                                              
                                            <ListItemText 
                                                primary={`${get(aggregations, "gross_cents_total_display")}`} 
                                                secondary="Imponibile">
                                            </ListItemText>
                                        </ListItem>                                                                                                                                                                       
                                    </List> 
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {headerColumns.map((headerColumn, index) => {
                                                    return <TableCell key={index}>
                                                        <span>{headerColumn}</span>
                                                    </TableCell>
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map(row => {
                                                return (
                                                    <TableRow key={row['contract-id']}>
                                                        <TableCell>{row['customer']}</TableCell>
                                                        <TableCell>{row['customer_code']}</TableCell>
                                                        <TableCell>{row['activation']}</TableCell>
                                                        <TableCell>{row['contract_id']}</TableCell>
                                                        <TableCell>{row['invoice_reference_number']}</TableCell>
                                                        <TableCell>{row['invoice_net_cents_display']}</TableCell>
                                                        <TableCell>{row['invoice_discount_cents_display']}</TableCell>
                                                        <TableCell>{row['invoice_gross_cents_display']}</TableCell>                                                        
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                    <Pagination 
                                        page={page} 
                                        setPage={this.setPage} 
                                        rowsPerPageOptions={[25]}
                                        perPage={perPage} 
                                        total={total}/>
                                </div>
                            : <CardContent>No results found</CardContent>
                        }
                </CardContent>                             
            </Card>
        );
    }

}

Report.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(Report);