import React from 'react';
import { 
    Filter, 
    TextInput, 
    SelectInput, 
    List, 
    Datagrid, 
    ChipField, 
    TextField, 
    FunctionField,
    Edit,
    SimpleForm,
    BooleanInput,
    Create,
    NullableBooleanInput,
} from 'react-admin';
import { 
    renderCentsCurrency,
    selectKind,
    DefaultBulkActionButtons,
    DefaultPagination,
} from './utils';
import CurrencyInput from '../inputs/CurrencyInput';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';

export { WifiTetheringIcon as ServiceIcon };

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <NullableBooleanInput label="Sconto Installazione" source="discounts-installation-price" />
        <SelectInput source="kind" label="Tipo" choices={selectKind}/>
    </Filter>
);

export const ServiceCreate = (props) => (
    <Create
      {...props}
      /* disable the app title change when shown */
      title="Nuovo Servizio Dati" 
    >
      <SimpleForm>
           <TextInput source="name" label="Nome" />
            <SelectInput source="kind" label="Tipo" choices={selectKind} /> 
            <BooleanInput source="discounts-installation-price" label="Sconto Installazione"/>  
            <CurrencyInput source="price-cents" label="Prezzo"/> 
      </SimpleForm>
    </Create>
  );

export const ServiceEdit = (props) => (
    <Edit
      {...props}
      /* disable the app title change when shown */
      title=" " 
    >
      <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`service_edit_${props.id}`}       
      >
           <TextInput source="name" label="Nome" />
            <SelectInput source="kind" label="Tipo" choices={selectKind} /> 
            <BooleanInput source="discounts-installation-price" label="Sconto Installazione"/>  
            <CurrencyInput source="price-cents" label="Prezzo"/> 
      </SimpleForm>
    </Edit>
  );

export const ServiceList = (props) => (
    <List 
        {...props}
        title="Servizi" 
        bulkActionButtons={<DefaultBulkActionButtons/>}
        perPage={25}
        pagination={<DefaultPagination />}
        filters={< Filters />}
        sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid expand={<ServiceEdit />}>   
            <TextField source="name" label="Nome"/>
            <FunctionField 
                source="price-cents" 
                label="Prezzo" 
                render={(record, source) => renderCentsCurrency(record[source], record['price-currency'])}/>
            <FunctionField 
                sortable={false} 
                source="net-cents" 
                label="Netto" 
                render={(record, source) => renderCentsCurrency(record[source], record['price-currency'])}/>
            <ChipField source="kind" label="kind"/>            
        </Datagrid>
    </List>
);