import React from 'react';
import { 
    Filter, 
    TextInput, 
    SelectInput, 
    List, 
    Datagrid, 
    ChipField, 
    TextField, 
    BooleanField,
    FunctionField,
    Edit,
    SimpleForm,
    NumberInput,
    BooleanInput,
    Create,
    NullableBooleanInput,
} from 'react-admin';
import { 
    selectKind,
    DefaultBulkActionButtons,
    DefaultPagination,
} from './utils';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';

export { CardGiftcardIcon as PromotionIcon };

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <NullableBooleanInput label="Try & Buy" source="try-and-buy" />
        <SelectInput source="kind" label="Tipo" choices={selectKind}/>
    </Filter>
);

export const PromotionCreate = (props) => (
    <Create
      {...props}     
        title="Nuova Promozione"  
    >
      <SimpleForm>
            <TextInput source="name" label="Nome" />
            <SelectInput source="kind" label="Tipo" choices={selectKind} />
            <NumberInput source="standard-installation-discount" label="Sconto Installazione Standard" />
            <NumberInput source="service-months-discount" label="Sconto Mensilità" />           
            <BooleanInput source="try-and-buy" label="Try & Buy"/>
      </SimpleForm>
    </Create>
  );

export const PromotionEdit = (props) => (
    <Edit
      {...props}     
        /* disable the app title change when shown */
        title=" " 
    >
      <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`promotion_edit_${props.id}`}       
      >
            <TextInput source="name" label="Nome" />
            <SelectInput source="kind" label="Tipo" choices={selectKind} />
            <NumberInput source="standard-installation-discount" label="Sconto Installazione Standard" />
            <NumberInput source="service-months-discount" label="Sconto Mensilità" />           
            <BooleanInput source="try-and-buy" label="Try & Buy"/>
      </SimpleForm>
    </Edit>
  );

export const PromotionList = (props) => (
    <List 
        {...props}
        title="Promozioni" 
        bulkActionButtons={<DefaultBulkActionButtons/>}
        perPage={25}
        pagination={<DefaultPagination />}
        filters={< Filters />}>
        <Datagrid expand={<PromotionEdit />}>   
            <TextField source="name" label="Nome"/>
            <FunctionField 
                label="Sconto" 
                render={record => `${record["standard-installation-discount"]}%`} />
            <TextField source="service-months-discount" label="Sconto Mesi"/>
            <ChipField source="kind" label="Tipo"/>            
            <BooleanField source="try-and-buy" label="Try & Buy"/>
        </Datagrid>
    </List>
);