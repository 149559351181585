import React from 'react';
import { 
    Filter, 
    TextInput, 
    SelectInput, 
    List, 
    Datagrid, 
    ChipField, 
    TextField, 
    BooleanField,
    FunctionField,
    Edit,
    SimpleForm,
    BooleanInput,
    Create,
    NullableBooleanInput,
} from 'react-admin';
import { 
    renderCentsCurrency,
    selectKind,
    DefaultBulkActionButtons,
    DefaultPagination,
} from './utils';
import CurrencyInput from '../inputs/CurrencyInput';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

export { LocalShippingIcon as InstallationIcon };

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <NullableBooleanInput label="Standard" source="standard" />
        <SelectInput source="kind" label="Tipo" choices={selectKind}/>
    </Filter>
);

export const InstallationCreate = (props) => (
    <Create
      {...props}     
      title="Nuova Tipologia Installazione"  
    >
      <SimpleForm>
            <TextInput source="name" label="Nome"/>
            <SelectInput source="kind" label="Tipo" choices={selectKind} />  
            <BooleanInput source="standard" label="Standard"/>  
            <CurrencyInput source="price-cents" label="Prezzo"  />
      </SimpleForm>
    </Create>
  );

export const InstallationEdit = (props) => (
    <Edit
      {...props}     
      /* disable the app title change when shown */
      title=" "  
    >
      <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`installation_edit_${props.id}`}       
      >
            <TextInput source="name" label="Nome"/>
            <SelectInput source="kind" label="Tipo" choices={selectKind} />  
            <BooleanInput source="standard" label="Standard"/>  
            <CurrencyInput source="price-cents" label="Prezzo"  />
      </SimpleForm>
    </Edit>
  );


export const InstallationList = (props) => (
    <List 
        {...props}
        title="Installazioni" 
        bulkActionButtons={<DefaultBulkActionButtons/>}
        perPage={25}
        pagination={<DefaultPagination />}
        filters={< Filters />}
        sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid expand={<InstallationEdit />}> 
            <TextField source="name" label="Nome"/>
            <FunctionField 
                source="price-cents" 
                label="Prezzo" 
                render={(record, source) => renderCentsCurrency(record[source], record['price-currency'])}/>
            <FunctionField 
                sortable={false} 
                source="net-cents" 
                label="Netto" 
                render={(record, source) => renderCentsCurrency(record[source], record['price-currency'])}/>
            <ChipField source="kind" label="Tipo"/>
            <BooleanField source="standard" label="Standard"/>              
        </Datagrid>
    </List>
);