import { kebabCase } from 'lodash'; 
import { AUTH_ERROR, AUTH_LOGIN, AUTH_CHECK, AUTH_LOGOUT } from 'react-admin';
import { baseApiUrl } from './App';

// https://github.com/lynndylanhurley/devise_token_auth#usage-tldr
export const SESSION_TOKEN = 'accessToken';
export const SESSION_EXPIRY = 'expiry';
export const SESSION_CLIENT = 'client';
export const SESSION_UID = 'uid';
export const SESSION_ACCOUNT_ID = 'accountId';
export const SESSION_ACCOUNT_USERNAME = 'username';
export const SESSION_TYPE = 'tokenType';
export const DEFAULT_SESSION_TYPE = 'Bearer';
export const SESSION_ROLES = 'roles';


export const requestSessionHeaders = {
  [kebabCase(SESSION_TOKEN)]: localStorage.getItem(SESSION_TOKEN),
  [kebabCase(SESSION_TYPE)]: DEFAULT_SESSION_TYPE,
  [kebabCase(SESSION_CLIENT)]: localStorage.getItem(SESSION_CLIENT),
  [kebabCase(SESSION_EXPIRY)]: localStorage.getItem(SESSION_EXPIRY),
  [kebabCase(SESSION_UID)]: localStorage.getItem(SESSION_UID),	  	  
};

export function setRequestSessionHeaders(headers) {
  headers.set(kebabCase(SESSION_TOKEN), localStorage.getItem(SESSION_TOKEN));
  headers.set(kebabCase(SESSION_TYPE), DEFAULT_SESSION_TYPE);
  headers.set(kebabCase(SESSION_CLIENT), localStorage.getItem(SESSION_CLIENT));
  headers.set(kebabCase(SESSION_EXPIRY), localStorage.getItem(SESSION_EXPIRY));
  headers.set(kebabCase(SESSION_UID), localStorage.getItem(SESSION_UID));
}

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(`${baseApiUrl}/auth/sign_in`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/vnd.api+json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                
                // https://github.com/lynndylanhurley/devise_token_auth#usage-tldr
                localStorage.setItem(SESSION_TOKEN, response.headers.get(kebabCase(SESSION_TOKEN)))
                localStorage.setItem(SESSION_EXPIRY, response.headers.get(kebabCase(SESSION_EXPIRY)))
                localStorage.setItem(SESSION_CLIENT, response.headers.get(kebabCase(SESSION_CLIENT)))
                localStorage.setItem(SESSION_UID, response.headers.get(kebabCase(SESSION_UID)))
                localStorage.setItem(SESSION_TYPE, response.headers.get(kebabCase(SESSION_TYPE)))                
                return response.json()
            })
            .then(({ data }) => {
            	const currentUser = data;
                localStorage.setItem(SESSION_ACCOUNT_ID, currentUser.id)
                localStorage.setItem(SESSION_ACCOUNT_USERNAME, currentUser.username)
                localStorage.setItem(SESSION_ROLES, JSON.stringify(currentUser.roles))
            });
    }
    if (type === AUTH_LOGOUT) {    	          
        const request = new Request(`${baseApiUrl}/auth/sign_out`, {
            method: 'DELETE',
            headers: new Headers({ 'Content-Type': 'application/vnd.api+json' }),
        })   
        // https://github.com/lynndylanhurley/devise_token_auth#usage-tldr
        request.headers.set(kebabCase(SESSION_TOKEN), localStorage.getItem(SESSION_TOKEN));
        request.headers.set(kebabCase(SESSION_UID), localStorage.getItem(SESSION_UID));
        request.headers.set(kebabCase(SESSION_CLIENT), localStorage.getItem(SESSION_CLIENT));
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                localStorage.removeItem(SESSION_TOKEN);
                localStorage.removeItem(SESSION_UID);
                localStorage.removeItem(SESSION_CLIENT);
                return Promise.resolve();
            });
    }   
    if (type === AUTH_ERROR) {
        const { status } = params;
        if (status === 401 || status === 403) {
            localStorage.removeItem(SESSION_TOKEN);
            localStorage.removeItem(SESSION_UID);
            localStorage.removeItem(SESSION_CLIENT);            
            return Promise.reject();
        }
        return Promise.resolve();
    }    
    if (type === AUTH_CHECK) {
    	// TODO: + SESSION_EXPIRY CHECK
        return localStorage.getItem(SESSION_TOKEN) ? Promise.resolve() : Promise.reject();
    }	 
    return Promise.resolve();
}