import React, { Component } from 'react';
import { baseApiUrl } from './App';
import { setRequestSessionHeaders } from './authClient';
import { stringify } from 'query-string';

export default class Wizard extends Component {

    state = {
        loading: false,
        data: {},
        error: ""
    }

    handleSubmit = (wizard, params, file) => {   

        this.setState({
            loading: true,
        }) 
    
        const headers = new Headers();
        //headers.set('Accept', 'application/vnd.api+json');
        headers.set('Mime-Type', 'application/octect-stream');
        setRequestSessionHeaders(headers); 
        var fileData;
        if (file) {
            fileData = new FormData();    
            fileData.append("file", file);
        }
        
        fetch(`${baseApiUrl}/v1/wizards/${wizard}?${stringify(params, {arrayFormat: 'index'})}`, { method: 'POST', headers: headers, body: fileData })
            .then(response => response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            })))
            .then(({ status, statusText, headers, body }) => {
                let json;
                try {
                    json = JSON.parse(body);
                } catch (e) {}
                if (status < 200 || status >= 300) {
                        this.setState({
                            loading: false,       
                            error: json.errors.map(error => error.detail).join(", "),
                        })                
                } else {
                        this.setState({
                            loading: false,
                            data: json.data,
                        })              	
                }
            });

    }

    render() {
        return React.cloneElement(
            this.props.children,
            {
                handleSubmit: this.handleSubmit,
                loading: this.state.loading,
                data: this.state.data,
                error: this.state.error
            },
        )
    }

}
