import React, { Component } from 'react';
import PropTypes from 'prop-types'; 
import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    Button,
    Typography,
    LinearProgress,
} from '@material-ui/core';
import { GET_LIST } from 'react-admin';
import { apiClient } from '../App';  

const styles = theme => ({
    container: {
        display: 'grid',
        flexWrap: 'wrap',   
        minWidth: 250,     
    },    
    textField: {},    
});

class SetInvoiceNumber extends Component {

    state = {
        fetching: false,
        next_reference_number: '',
        current_reference_number: '',
      };

        componentWillMount() {
            this.loadSetting()
        }   
        
        loadSetting = () => {

            this.setState({fetching: true})

            const PER_PAGE=1000

            const DEFAULT_PARAMS = {
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: PER_PAGE },
            }

            const params = {
                ...DEFAULT_PARAMS,
                filter: { 'value-id': 'start_next_invoice_from_number' },
            }
            
            apiClient(GET_LIST, 'settings', params)
                .then((response) => {
                    this.setState({
                        current_reference_number: response.data[0].value,
                        fetching: false,
                    });
                }) 	  
        }      
    
      valid = () => {
          return !(this.state.next_reference_number === '')
      }

      handleSubmit = () => {                  
          this.props.handleSubmit(this.props.wizard, this.state, null)
      }
    
      render() {
        const { 
            title,
            classes,
            loading,
        } = this.props;
        const { 
            fetching,
            next_reference_number, 
            current_reference_number,
        } = this.state;
    
        return (
            <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="headline" gutterBottom>{title}</Typography>
                {fetching ? 
                    <LinearProgress className={classes.loader} mode="indeterminate"/> :
                    <span>
                        <TextField
                            label="Prossimo Numero Fattura"
                            helperText={`La prossima fattura sarà la numero ${current_reference_number}`}
                            className={classes.textField}
                            value={next_reference_number}
                            onChange={(event) => {
                                this.setState({
                                    ...this.state,
                                    next_reference_number: event.target.value,
                                }) 
                            }}
                            margin="normal"
                        />    
                        <Button
                            variant="contained" 
                            fullWidth={true}  
                            color="primary"
                            onClick={this.handleSubmit}
                            disabled={!this.valid() && !loading}>
                            Invia
                        </Button>
                    </span>
                }                
            </form>    
        );
      }

}

SetInvoiceNumber.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
};
    
SetInvoiceNumber.defaultProps = {
    title: 'Imposta Nr. Fattura',
};

export default withStyles(styles)(SetInvoiceNumber);
