import React, { Component } from 'react';
import PropTypes from 'prop-types';  
import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    Button,
    FormControl,
    Typography,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import { capitalize } from 'lodash';
import FileInput from '../inputs/FileInput';

const styles = theme => ({
    container: {
        display: 'grid',
        flexWrap: 'wrap',
        minWidth: 250,
    },    
    textField: {},    
});

class Send extends Component {
    state = {
        kind: 'consumer',
        file: '',
        email_ccn: '',
        emitted_from: new Date(moment().startOf('month').format()),
        emitted_to: new Date(moment().endOf('month').format()),
        attachment_file_name: 'Informazioni Supplementari',
      };
    
      valid = () => {
          return !(this.state.email_ccn === '')
      }

      handleSubmit = () => {
        const { 
            emitted_from, 
            emitted_to,
        } = this.state;

          this.props.handleSubmit(this.props.wizard, {
            ...this.state,
            emitted_from: emitted_from instanceof Date ? emitted_from.toISOString() : emitted_from.format(),
            emitted_to: emitted_to instanceof Date ? emitted_to.toISOString() : emitted_to.format(),
          }, null)
      }
    
      render() {
        const { title, classes } = this.props;
        const { 
            emitted_from, 
            emitted_to,
            email_ccn,
            kind,
            attachment_file_name,
        } = this.state;

        const kindChoices = ['consumer', 'business'];
    
        return (
            <span>
            <Typography variant="headline" gutterBottom>{title}</Typography>
            <form className={classes.container} noValidate autoComplete="off">                
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="kind">Tipo</InputLabel>
                    <Select
                        value={kind}              
                        onChange={(event) => { this.setState({kind: event.target.value}) }}>
                        {kindChoices.map(kindChoice => {
                            return <MenuItem
                                        key={kindChoice}
                                        value={kindChoice}>
                                            {capitalize(kindChoice)}
                                    </MenuItem>
                        })}
                    </Select> 
                </FormControl>
                <MuiPickersUtilsProvider utils={MomentUtils} locale='it' moment={moment}>
                    <DatePicker
                        margin="normal"
                        label="Data Emissione da"
                        value={emitted_from}
                        cancelLabel="Annulla"
                        format='DD/MM/YYYY'
                        onChange={(date) => {
                            this.setState({            	 		   
                                ...this.state,
                                emitted_from: date,
                            })              	 
                        }}
                    />
                    <DatePicker
                        margin="normal"
                        label="Data Emissione a"
                        value={emitted_to}
                        cancelLabel="Annulla"
                        format='DD/MM/YYYY'
                        onChange={(date) => {
                            this.setState({            	 		   
                                ...this.state,
                                emitted_to: date,
                            })              	 
                        }}
                    />              
                </MuiPickersUtilsProvider>
                <FileInput 
                    onChange={(file) => {
                        this.setState({
                            ...this.state,
                            file: file,
                            attachment_file_name: file.name.split(".")[0]
                        });
                    }}
                />                
                <TextField
                    label="Nome alleagto"
                    className={classes.textField}
                    value={attachment_file_name}
                    onChange={(event) => {
                        this.setState({
                            ...this.state,
                            attachment_file_name: event.target.value,
                        }) 
                    }}
                    margin="normal"
                />                  
                <TextField
                    label="Invia in CCN a"
                    helperText="Inserisci i destinatari separati da una virgola."
                    className={classes.textField}
                    value={email_ccn}
                    onChange={(event) => {
                        this.setState({
                            ...this.state,
                            email_ccn: event.target.value,
                        }) 
                    }}
                    margin="normal"
                />    
                <Button
                    variant="contained" 
                    fullWidth={true}  
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={!this.valid()}>
                    Invia
                </Button>                  
            </form>   
            </span> 
        );
      }

}

Send.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
};
    
Send.defaultProps = {
    title: 'Invia Fatture',
};

export default withStyles(styles)(Send);
