import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DownloadButton from './DownloadButton';
import { baseApiUrl } from '../App';
import { requestSessionHeaders } from '../authClient';
import axios from "axios";

class DownloadFile extends Component {

    download = (done) => {

        const { record, resource, format, endpoint } = this.props;
        const url = record === undefined ? `${baseApiUrl}/v1/${endpoint}` : `${baseApiUrl}/v1/${format}/${resource}/${record.id}`
        const axiosCfg = {
            url: url,
            method: 'get',
            headers: {
                ...requestSessionHeaders
            },
            responseType: 'arraybuffer'
        }

        const client = axios.create({});

        client(axiosCfg).then(response => {                        
            const headers = new Headers(response.headers)
            const filename = headers.get("content-disposition").split(";")[1].split("=")[1].replace(/"/g,'')
            done({
                filename: filename, 
                contents: response.data
            });
        }).catch(error => console.error(error))

    }

    render() {
        const {
            record, 
            resource, 
            label, 
            style, 
            ...other
        } = this.props;

        return <DownloadButton
                generateTitle={label}
                loadingTitle="Stampando.."
                showFullTitle={false}
                async={true}
                genFile={this.download}
                {...other}
            />       
    }
}

DownloadFile.propTypes = {
    record: PropTypes.object,
    resource: PropTypes.string,
    showNotification: PropTypes.func,
    label: PropTypes.string,
    format: PropTypes.string,
};

DownloadFile.defaultProps = {
    label: "PDF",
    format: "pdf"
};

export default DownloadFile;
