import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Card,
} from '@material-ui/core'
import MomentUtils from '@date-io/moment'
import {MuiPickersUtilsProvider, DatePicker} from 'material-ui-pickers'
import moment from 'moment'
import {capitalize, snakeCase} from 'lodash'
import FileInput from '../inputs/FileInput'
import {Title, GET_LIST} from 'react-admin'
import {apiClient} from '../App'
import {
  selectKind,
  selectPaymentMethods,
  selectInvoicingFrequencies,
} from '../resources/utils'
import {CurrencyInputWithStyle} from '../inputs/CurrencyInput'
import Wizard from '../Wizard'
import {showNotification} from 'react-admin'
import {connect} from 'react-redux'
import {push} from 'react-router-redux'

const RESOURCES = [
  'customers',
  'services',
  'voice-services',
  'installations',
  'addons',
  'promotions',
]

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: '1em',
  },
  container: {
    display: 'grid',
    flexWrap: 'wrap',
    minWidth: 250,
  },
  textField: {},
  formControl: {
    margin: theme.spacing.unit,
    minHeight: 48,
  },
  loader: {
    marginTop: 16,
    marginBottom: 8,
    top: 32,
  },
})

class CreateContract extends Component {
  state = {
    kind: 'consumer',
    customer_id: '',
    activation_same_as_invoicing_address: true,
    activation_id: '',
    service_id: '',
    voice_service_id: '',
    installation_id: '',
    discount: 0,
    addon_ids: [],
    promotion_id: '',
    promoter_code: '',
    payment_method: 'sdd',
    invoicing_frequency: 'bimestrale',
    subscription_date: new Date(moment().format()),
    activation_date: new Date(moment().format()),
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data && this.props.data !== {}) {
      this.props.push('/contracts')
    }
    if (this.props.error !== prevProps.error && this.props.error !== '') {
      this.props.showNotification(this.props.error)
    }
  }

  componentWillMount() {
    this.fetchData(this.state.kind)
  }

  loadResource = (resource, key, value) => {
    const PER_PAGE = 1000

    const DEFAULT_PARAMS = {
      sort: {field: 'name', order: 'ASC'},
      pagination: {page: 1, perPage: PER_PAGE},
    }

    const params = {
      ...DEFAULT_PARAMS,
      filter: {[key]: value},
    }

    apiClient(GET_LIST, resource, params).then((response) => {
      this.setState({
        [snakeCase(resource)]: response.data,
      })
    })
  }

  fetchData = (kind) => {
    RESOURCES.map((resource) => this.loadResource(resource, 'kind', kind))
  }

  renderSelect = (
    title,
    key,
    data,
    options = {nameDisplayType: 0, multiple: false},
  ) => {
    const {classes} = this.props

    const {multiple, nameDisplayType} = options

    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor={key}>{title}</InputLabel>
        {data ? (
          <Select
            name={capitalize(key)}
            multiple={multiple}
            input={<Input name={capitalize(key)} id={key} />}
            inputProps={{
              name: capitalize(key),
              id: key,
            }}
            value={this.state[key]}
            onChange={(event) => {
              key === 'kind' && this.fetchData(event.target.value)
              this.setState({[key]: event.target.value})
            }}
          >
            {data &&
              data.map((kindChoice) => {
                var choiceName
                switch (nameDisplayType) {
                  case 0: {
                    choiceName = kindChoice.name
                    break
                  }
                  case 1: {
                    choiceName = `${kindChoice.name} ${kindChoice.surname}`
                    break
                  }
                  case 2: {
                    choiceName = `${kindChoice.code} - ${kindChoice.name} ${
                      kindChoice.surname
                    }`
                    break
                  }
                  case 3: {
                    choiceName = capitalize(kindChoice.name)
                    break
                  }
                  default: {
                    choiceName = kindChoice.name
                    break
                  }
                }
                return (
                  <MenuItem key={kindChoice.id} value={kindChoice.id}>
                    {choiceName}
                  </MenuItem>
                )
              })}
          </Select>
        ) : (
          <LinearProgress className={classes.loader} mode="indeterminate" />
        )}
      </FormControl>
    )
  }

  renderRadioGroup = (title, key, data) => {
    const {classes} = this.props

    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">{title}</FormLabel>
        <RadioGroup
          aria-label={title}
          name={key}
          className={classes.group}
          value={this.state[key]}
          onChange={(event) => {
            this.setState({[key]: event.target.value})
          }}
        >
          {data &&
            data.map((kindChoice) => {
              return (
                <FormControlLabel
                  key={kindChoice.id}
                  value={kindChoice.id}
                  control={<Radio />}
                  label={kindChoice.name}
                />
              )
            })}
        </RadioGroup>
      </FormControl>
    )
  }

  valid = () => {
    const {
      customer_id,
      // activation_same_as_invoicing_address,
      // activation_id,
      service_id,
      installation_id,
      // addon_ids,
      // promotion_id,
      // promoter_code,
      // payment_method,
      // invoicing_frequency,
      // subscription_date,
      // activation_date,
    } = this.state

    return (
      customer_id !== null && service_id !== null && installation_id !== null
    )
  }

  handleSubmit = () => {
    const {
      kind,
      customer_id,
      activation_same_as_invoicing_address,
      activation_id,
      service_id,
      voice_service_id,
      installation_id,
      discount,
      addon_ids,
      promotion_id,
      promoter_code,
      payment_method,
      invoicing_frequency,
      subscription_date,
      activation_date,
    } = this.state

    this.props.handleSubmit(
      this.props.wizard,
      {
        kind: kind,
        customer_id: customer_id,
        activation_same_as_invoicing_address: activation_same_as_invoicing_address,
        activation_id: activation_id,
        service_id: service_id,
        voice_service_id: voice_service_id,
        installation_id: installation_id,
        discount: discount,
        addon_ids: addon_ids,
        promotion_id: promotion_id,
        promoter_code: promoter_code,
        payment_method: payment_method,
        invoicing_frequency: invoicing_frequency,
        subscription_date:
          subscription_date instanceof Date
            ? subscription_date.toISOString()
            : subscription_date.format(),
        activation_date:
          activation_date instanceof Date
            ? activation_date.toISOString()
            : activation_date.format(),
      },
      null,
    )
  }

  render() {
    const {title, classes, loading} = this.props

    const {
      // data
      customers,
      activations,
      services,
      voice_services,
      installations,
      addons,
      promotions,
      // wizard
      activation_same_as_invoicing_address,
      subscription_date,
      activation_date,
      customer_id,
    } = this.state

    return (
      <Card className={classes.root}>
        <Title title={title} />
        <form className={classes.container} noValidate autoComplete="off">
          {this.renderSelect('Tipo', 'kind', selectKind, {nameDisplayType: 3})}
          {this.renderSelect('Cliente', 'customer_id', customers, {
            nameDisplayType: 1,
          })}

          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={activation_same_as_invoicing_address}
                  onChange={(event) => {
                    !event.target.checked &&
                      this.loadResource(
                        'activations',
                        'customer_id',
                        customer_id,
                      )
                    this.setState({
                      ...this.state,
                      activation_same_as_invoicing_address:
                        event.target.checked,
                    })
                  }}
                />
              }
              label="Indirizzo attivazione coincide con indirizzo di fatturazione?"
            />
          </FormControl>

          {!activation_same_as_invoicing_address &&
            this.renderSelect('Attivazione', 'activation_id', activations, {
              nameDisplayType: 1,
            })}
          {this.renderSelect('Cliente Amico', 'promoter_code', customers, {
            nameDisplayType: 2,
          })}
          {this.renderSelect('Servizio Dati', 'service_id', services)}
          {this.renderSelect('Servizi Aggiuntivi', 'addon_ids', addons, {
            nameDisplayType: 0,
            multiple: true,
          })}
          {this.renderSelect(
            'Servizio Voce',
            'voice_service_id',
            voice_services,
          )}
          {this.renderSelect(
            'Tipologia Installazione',
            'installation_id',
            installations,
          )}
          {this.renderSelect('Promozione', 'promotion_id', promotions)}
          {this.renderRadioGroup(
            'Modalità di pagamento',
            'payment_method',
            selectPaymentMethods,
          )}
          {this.renderRadioGroup(
            'Fatturazione',
            'invoicing_frequency',
            selectInvoicingFrequencies,
          )}

          <FormControl className={classes.formControl}>
            <CurrencyInputWithStyle
              label="Sconto"
              id="discount"
              record={{discount: 0.0}}
              onChange={(value, _) => {
                this.setState({
                  ...this.state,
                  discount: value,
                })
              }}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale="it"
              moment={moment}
            >
              <DatePicker
                margin="normal"
                label="Data Sottoscrizione"
                value={subscription_date}
                cancelLabel="Annulla"
                format="DD/MM/YYYY"
                onChange={(date) => {
                  this.setState({
                    ...this.state,
                    subscription_date: date,
                  })
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale="it"
              moment={moment}
            >
              <DatePicker
                margin="normal"
                label="Data Attivazione"
                value={activation_date}
                cancelLabel="Annulla"
                format="DD/MM/YYYY"
                onChange={(date) => {
                  this.setState({
                    ...this.state,
                    activation_date: date,
                  })
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <FormControl className={classes.formControl}>
            <FormLabel htmlFor="file">Contratto Originale</FormLabel>
            <FileInput
              onChange={(file) => {
                this.setState({
                  ...this.state,
                  file: file,
                })
              }}
            />
          </FormControl>

          <Button
            variant="contained"
            fullWidth={true}
            color="primary"
            onClick={this.handleSubmit}
            disabled={!this.valid() && !loading}
          >
            Invia
          </Button>
        </form>
      </Card>
    )
  }
}

CreateContract.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
}

CreateContract.defaultProps = {
  title: 'Nuovo Contratto',
  wizard: 'create_contract',
}

const StyledCreateContract = withStyles(styles)(CreateContract)
const ConnectedStyledCreateContract = connect(
  null,
  {
    showNotification,
    push,
  },
)(StyledCreateContract)

const CreateContractWizard = () => {
  return (
    <Wizard>
      <ConnectedStyledCreateContract />
    </Wizard>
  )
}
export default CreateContractWizard
//export default withStyles(styles)(CreateContract);
