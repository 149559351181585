import React from 'react';
import { 
    Filter, 
    TextInput, 
    SelectInput, 
    List, 
    Datagrid, 
    ChipField, 
    TextField, 
    BooleanField,
    NullableBooleanInput,
    BooleanInput,
    FunctionField,
    Edit,
    SimpleForm,
    Create,
} from 'react-admin';
import { 
    renderCentsCurrency,
    selectType,
    selectKind,
    DefaultBulkActionButtons,
    DefaultPagination,
} from './utils';
import CurrencyInput from '../inputs/CurrencyInput';
import WidgetsIcon from '@material-ui/icons/Widgets';

export { WidgetsIcon as AddonIcon };

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <NullableBooleanInput label="Ricorrente" source="recurring" />
        <SelectInput source="kind" label="Tipo" choices={selectKind}/>
        <SelectInput source="typology" label="Tipologia" choices={selectType}  />
    </Filter>
);

export const AddonCreate = (props) => (
    <Create
      {...props}
      title="Nuovo Servizio Aggiuntivo"     
      >
      <SimpleForm
        defaultValue={{"price-cents": 0}}
        >
           <TextInput source="name" label="Nome"/>
           <SelectInput source="typology" label="Tipologia" choices={selectType}  />      
           <SelectInput source="kind" label="Tipo" choices={selectKind}  />
           <CurrencyInput source="price-cents" label="Prezzo"  />        
           <BooleanInput source="recurring" label="Ricorrente" />    
      </SimpleForm>
    </Create>
  );

export const AddonEdit = (props) => (
    <Edit
      {...props}
      title=" "         
    >
      <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`addon_edit_${props.id}`}       
      >
           <TextInput source="name" label="Nome"/>
           <SelectInput source="typology" label="Tipo" choices={selectType}  />      
           <SelectInput source="kind" label="Tipologia" choices={selectKind}  /> 
           <CurrencyInput source="price-cents" label="Prezzo"  />      
           <BooleanInput source="recurring" label="Ricorrente" />    
      </SimpleForm>
    </Edit>
  );

export const AddonList = (props) => (
    <List 
        {...props}
        title="Servizi Aggiuntivi" 
        bulkActionButtons={<DefaultBulkActionButtons/>}
        perPage={25}
        pagination={<DefaultPagination />}
        filters={< Filters />}>
        <Datagrid expand={<AddonEdit />}>   
            <TextField source="name" label="Nome"/>                        
            <FunctionField 
                source="price-cents" 
                label="Prezzo" 
                render={(record, source) => renderCentsCurrency(record[source], record['price-currency'])}/>
            <FunctionField 
                sortable={false} 
                source="net-cents" 
                label="Netto" 
                render={(record, source) => renderCentsCurrency(record[source], record['price-currency'])}/>
            <ChipField source="typology" label="Tipologia"/>
            <ChipField source="kind" label="Tipo"/>
            <BooleanField source="recurring" label="Ricorrente"/>
        </Datagrid>
    </List>
);