import React from 'react';
import { Admin, Resource, fetchUtils } from 'react-admin';
import dataClient from './dataClient';
import authClient, { setRequestSessionHeaders } from './authClient';
import {
  AddonList,
  AddonCreate,
  AddonIcon,
  ContractList,
  ContractIcon,
  CustomerList,
  CustomerIcon,
  CustomerCreate,
  ActivationIcon,
  ActivationList,
  ActivationCreate,
  InstallationList,
  InstallationIcon,
  InstallationCreate,
  InvoiceList,
  InvoiceIcon,
  ServiceList,
  ServiceIcon,
  ServiceCreate,
  PromotionList,
  PromotionIcon,
  PromotionCreate,
  SimpleInvoiceList,
  SimpleInvoiceIcon,
  SimpleInvoiceCreate,
  SimpleServiceList,
  SimpleServiceIcon,
  SimpleServiceCreate,
  VoiceServiceList,
  VoiceServiceIcon,
  VoiceServiceCreate,
} from './resources';
import MyLayout from './MyLayout';
import myTheme from './myTheme';
import Dashboard from './Dashboard';
import customRoutes from './customRoutes';
import moment from 'moment';
import 'moment/locale/it';
import italianMessages from 'ra-language-italian';

// Locale
const LOCALE='it';
moment.locale(LOCALE);
const messages = {
  it: italianMessages,
}
const i18nProvider = locale => messages[locale];

// API
export const baseApiUrl = process.env.REACT_APP_API_PROTOCOL && process.env.REACT_APP_API_ADDRESS && process.env.REACT_APP_API_PORT
  ? `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_ADDRESS}:${process.env.REACT_APP_API_PORT}/api`
  : `/api`;

const httpClient = (url, options) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/vnd.api+json' });
      options.headers.set('Content-Type', 'application/vnd.api+json');
    }
    setRequestSessionHeaders(options.headers);
    return fetchUtils.fetchJson(url, options);
}

export const apiClient = dataClient(`${baseApiUrl}/v2`, httpClient);

// App
const App = () =>
      <Admin
        title="AllWiFi - Fatturazione"
        locale={LOCALE}
        i18nProvider={i18nProvider}
        appLayout={MyLayout}
        dashboard={Dashboard}
        customRoutes={customRoutes}
        authProvider={authClient}
        dataProvider={apiClient}
        theme={myTheme}>
        {permissions => [
          <Resource
            name="activations"
            icon={ActivationIcon}
            list={ActivationList}
            create={ActivationCreate}
            options={{ label: 'Attivazioni' }}
          />,
          <Resource
            name="addons"
            icon={AddonIcon}
            list={AddonList}
            create={AddonCreate}
            options={{ label: 'Servizi Aggiuntivi' }}
          />,
          <Resource
            name="contracts"
            icon={ContractIcon}
            list={ContractList}
            options={{ label: 'Contratti' }}
          />,
          <Resource
            name="customers"
            icon={CustomerIcon}
            list={CustomerList}
            create={CustomerCreate}
            options={{ label: 'Clienti' }}
          />,
          <Resource
            name="installations"
            icon={InstallationIcon}
            list={InstallationList}
            create={InstallationCreate}
            options={{ label: 'Tipologie Installazione' }}
          />,
          <Resource
            name="invoices"
            icon={InvoiceIcon}
            list={InvoiceList}
            options={{ label: 'Fatture' }}
          />,
          <Resource
            name="simple-invoices"
            icon={SimpleInvoiceIcon}
            list={SimpleInvoiceList}
            create={SimpleInvoiceCreate}
            options={{ label: 'Fatture Semplici' }}
          />,
          <Resource
            name="promotions"
            icon={PromotionIcon}
            list={PromotionList}
            create={PromotionCreate}
            options={{ label: 'Promozioni' }}
          />,
          <Resource
            name="services"
            icon={ServiceIcon}
            list={ServiceList}
            create={ServiceCreate}
            options={{ label: 'Servizi Dati' }}
          />,
          <Resource
            name="simple-services"
            icon={SimpleServiceIcon}
            list={SimpleServiceList}
            create={SimpleServiceCreate}
            options={{ label: 'Servizi Semplici' }}
          />,
          <Resource
            name="voice-services"
            icon={VoiceServiceIcon}
            list={VoiceServiceList}
            create={VoiceServiceCreate}
            options={{ label: 'Servizi Voce' }}
          />,
        ]}
      </Admin>

export default App;