import React from 'react';
import { 
    Filter, 
    TextInput, 
    List, 
    Datagrid, 
    DateField, 
    TextField, 
    FunctionField,
    NumberInput,
    ReferenceField,
    Edit,
    SimpleForm,
    CardActions, 
    CreateButton, 
    ExportButton, 
    RefreshButton,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { DateInput } from '../inputs/DateInput';
import { 
    selectKind,
    selectPaymentMethods,
    selectInvoicingFrequencies,
    nameRenderer,
    renderCentsCurrency,    
    DefaultBulkActionButtons,
    DefaultPagination,
} from './utils';
import CurrencyInput from '../inputs/CurrencyInput';
import DownloadFile from '../buttons/DownloadFile';
import SendEmail from '../buttons/SendEmail';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

export { AccountBalanceIcon as InvoiceIcon };

const actionStyles = {
    button: {
        marginLeft: 8,
    },
};

const InvoiceActions = withStyles(actionStyles)(({
    classes,
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <SendEmail 
            endpoint='email_current_cycle_invoices' 
            label='Invia Correnti'/>
        <DownloadFile 
            endpoint="reports/yearly" 
            label="Scarica CSV"
            className={classes.button} 
            fullWidth={false}/>
        <CreateButton basePath={basePath} />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        <RefreshButton />
    </CardActions>
));

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput 
            label="Cliente" 
            source="customer-id" 
            reference="customers"
            perPage={1000} >
            <AutocompleteInput optionText={nameRenderer} />
        </ReferenceInput> 
        <SelectInput source="kind" label="Tipo" choices={selectKind}  />
        <SelectInput source="invoicing-frequency" label="Fatturazione" choices={selectInvoicingFrequencies}  /> 
        <SelectInput source="payment-method" label="Pagamento" choices={selectPaymentMethods}  />      
    </Filter>
);

export const InvoiceEdit = (props) => (     
    <Edit
      {...props}   
      /* disable the app title change when shown */
      title=" "    
    >
        <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`invoice_edit_${props.id}`}        
            >                      
            <TextInput 
                source="name" 
                label="Nome"/>
            <NumberInput 
                source="reference-number" 
                label="Nr."/>    
            <ReferenceField 
                source="contract-id"  
                label="Contratto" 
                reference="contracts" 
                addLabel >
                <TextField source="name" />
            </ReferenceField>
           <DateInput 
                source="emission-date" 
                label="Data Emissione"/>
          <DateInput 
                source="start-date" 
                label="Data Inizio"/>
          <DateInput 
                source="end-date" 
                label="Data Fine"/>    
          <DateInput 
                source="due-date" 
                label="Data Scadenza"/>
          <DateInput 
                source="sent-at" 
                label="Data Invio"/>                           
            <CurrencyInput                 
                source="line-items['service_price_cents']" 
                label="Servizio Dati"/>         
            <CurrencyInput                
                source="line-items['voice_service_price_cents']" 
                label="Servizio Voce"/>
            <CurrencyInput                 
                source="line-items['recurring_addons_price_cents']" 
                label="Servizi Aggiuntivi (Ricorrenti)"/>
            <CurrencyInput                 
                source="line-items['discount_cents']" 
                label="Sconto"/>
            <CurrencyInput               
                source="line-items['promotion_discount_cents']" 
                label="Sconto Promozione"/>         
            <CurrencyInput               
                source="line-items['installation_price_cents']" 
                label="Installazione"/>         
            <CurrencyInput                
                source="line-items['voice_activation_price_cents']" 
                label="Attivazione Voce"/>         
            <CurrencyInput                 
                source="line-items['first_time_addons_price_cents']" 
                label="Servizi Aggiuntivi (Una-Tantum)"/>             
      </SimpleForm>
    </Edit>
  );

export const InvoiceList = (props) => (
    <List 
        {...props}
        title="Fatture" 
        actions={<InvoiceActions />}
        bulkActionButtons={<DefaultBulkActionButtons/>}
        perPage={25}
        pagination={<DefaultPagination />}
        filters={< Filters />}
        sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid expand={<InvoiceEdit />}>
            <TextField source="name" label="Nome"/>   
            <FunctionField 
                source="net-cents" 
                label="Netto" 
                render={(record, source) => renderCentsCurrency(record[source], record['net-currency'])}/>     
            <FunctionField 
                sortable={false} 
                source="vat-cents" 
                label="IVA" 
                render={(record, source) => renderCentsCurrency(record[source], record['net-currency'])}/> 
            <FunctionField 
                sortable={false} 
                source="gross-cents" 
                label="Totale" 
                render={(record, source) => renderCentsCurrency(record[source], record['net-currency'])}/> 
            <TextField source="reference-number" label="Nr."/>
            <DateField locales="it-IT" source="emission-date" label="Emissione"/>
            <DateField locales="it-IT" source="start-date" label="Inizio"/>
            <DateField locales="it-IT" source="end-date" label="Fine"/>      
            <DownloadFile resource="invoices" format="pdf" />
            <DownloadFile resource="invoices" format="xml" label="SDD"/>
            <DownloadFile resource="invoices" format="fa" label="FA"/>
            <SendEmail resource="invoices"/>                     
        </Datagrid>
    </List>
);