import React from 'react';
import { 
    Filter, 
    TextInput, 
    List, 
    Datagrid, 
    TextField, 
    FunctionField,
    Edit,
    SimpleForm,
    LongTextInput,
    Create,
} from 'react-admin';
import { 
    renderCentsCurrency,
    DefaultBulkActionButtons,
    DefaultPagination,
} from './utils';
import CurrencyInput from '../inputs/CurrencyInput';
import StyleIcon from '@material-ui/icons/Style';

export { StyleIcon as SimpleServiceIcon };

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const SimpleServiceCreate = (props) => (
    <Create
      {...props}
      title="Nuovo Servizio Semplice"
    >
      <SimpleForm>
            <TextInput source="name" label="Nome" />
            <LongTextInput source="description" label="Descrizione" />
            <CurrencyInput source="price-cents" label="Prezzo"  /> 
      </SimpleForm>
    </Create>
  );


export const SimpleServiceEdit = (props) => (
    <Edit
      {...props}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`simple_service_edit_${props.id}`}       
      >
            <TextInput source="name" label="Nome" />
            <LongTextInput source="description" label="Descrizione" />
            <CurrencyInput source="price-cents" label="Prezzo"  /> 
      </SimpleForm>
    </Edit>
  );

export const SimpleServiceList = (props) => (
    <List 
        {...props}
        title="Servizi Semplici" 
        bulkActionButtons={<DefaultBulkActionButtons/>}
        perPage={25}
        pagination={<DefaultPagination />}
        filters={< Filters />}
        sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid expand={<SimpleServiceEdit />}>   
            <TextField source="name" label="Nome"/>
            <TextField source="description" label="Descrizione"/>
            <FunctionField 
                source="price-cents" 
                label="Prezzo" 
                render={(record, source) => renderCentsCurrency(record[source], record['price-currency'])}/>           
        </Datagrid>
    </List>
);