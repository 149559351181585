import React from 'react';
import { 
    Filter, 
    TextInput, 
    List, 
    Datagrid, 
    ChipField, 
    DateField, 
    TextField, 
    ReferenceField,
    FunctionField,
    Edit,
    SimpleForm,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    AutocompleteInput,
    Create,
    ReferenceInput,
} from 'react-admin';
import { DateInput } from '../inputs/DateInput';
import { 
    renderCentsCurrency,
    nameRenderer, 
    DefaultBulkActionButtons,
    DefaultPagination,
} from './utils';
import CurrencyInput from '../inputs/CurrencyInput';
import DownloadFile from '../buttons/DownloadFile';
import SendEmail from '../buttons/SendEmail';
import ReceiptIcon from '@material-ui/icons/Receipt';

export { ReceiptIcon as SimpleInvoiceIcon };

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput 
            label="Cliente" 
            source="customer-id" 
            reference="customers">
            <AutocompleteInput optionText={nameRenderer} />
        </ReferenceInput>         
    </Filter>
);

export const SimpleInvoiceCreate = (props) => (     
    <Create
      {...props} 
      title="Nuova Fattura Semplice" 
    >
          <SimpleForm>           
              <NumberInput 
                  source="reference-number" 
                  label="Nr."/>   
                <CurrencyInput 
                    source="discount" 
                    label="Sconto"/> 
              <ReferenceInput
                  source="customer-id"  
                  label="Cliente" 
                  reference="customers" 
                  allowEmpty >
                  <AutocompleteInput label="Name" optionText={nameRenderer} />
              </ReferenceInput>
              <DateInput 
                  source="emission-date" 
                  label="Data Emissione"/>
              <DateInput 
                  source="due-date" 
                  label="Data Scadenza"/>                 
              <ReferenceArrayInput source="simple-service-ids" reference="simple-services" label="Servizi">     
                  <AutocompleteArrayInput>
                          <ChipField source="name" />
                  </AutocompleteArrayInput>                 
              </ReferenceArrayInput>
              <ReferenceArrayInput source="addon-ids" reference="addons" label="Servizi Aggiuntivi">     
                  <AutocompleteArrayInput>
                          <ChipField source="name" />
                  </AutocompleteArrayInput>                 
              </ReferenceArrayInput>            
      </SimpleForm>
    </Create>
  );

export const SimpleInvoiceEdit = (props) => (     
  <Edit
    {...props} 
    /* disable the app title change when shown */
    title=" "   
  >
      <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`simple_invoice_edit_${props.id}`}       
      >           
            <TextInput 
                source="name" 
                label="Nome"/>
            <NumberInput 
                source="reference-number" 
                label="Nr."/>   
            <CurrencyInput 
                source="discount-cents" 
                label="Sconto"/> 
            <ReferenceField 
                source="customer-id"  
                label="Cliente" 
                reference="customers" 
                addLabel >
                <FunctionField label="Name" render={choice => `${choice.name} ${choice.surname}`} />
            </ReferenceField>
            <DateInput 
                source="emission-date" 
                label="Data Emissione"/>
            <DateInput 
                source="due-date" 
                label="Data Scadenza"/>
            <DateField 
                source="sent-at" 
                label="Data Invio"/>                   
            <ReferenceArrayInput source="simple-service-ids" reference="simple-services" label="Servizi">     
                <AutocompleteArrayInput>
                        <ChipField source="name" />
                </AutocompleteArrayInput>                 
            </ReferenceArrayInput>
            <ReferenceArrayInput source="addon-ids" reference="addons" label="Servizi Aggiuntivi">     
                <AutocompleteArrayInput>
                        <ChipField source="name" />
                </AutocompleteArrayInput>                 
            </ReferenceArrayInput>            
    </SimpleForm>
  </Edit>
);

export const SimpleInvoiceList = (props) => (
    <List 
        {...props}
        title="Fatture Semplici" 
        bulkActionButtons={<DefaultBulkActionButtons/>}
        perPage={25}
        pagination={<DefaultPagination />}
        filters={< Filters />}
        sort={{ field: 'reference_number', order: 'DESC' }}>
        <Datagrid expand={<SimpleInvoiceEdit />}>   
            <TextField source="name" label="name"/>
            <ReferenceField 
                label="Cliente" 
                source="customer-id" 
                reference="customers"
                linkType={false}
                sortBy="customer.surname">
                <FunctionField label="Name" render={nameRenderer} />
            </ReferenceField>                         
            <FunctionField 
                source="net-cents" 
                label="Netto" 
                render={(record, source) => renderCentsCurrency(record[source], record['net-currency'])}/>
            <FunctionField 
                sortable={false} 
                source="vat-cents" 
                label="IVA" 
                render={(record, source) => renderCentsCurrency(record[source], record['net-currency'])}/> 
            <FunctionField 
                sortable={false} 
                source="gross-cents" 
                label="Totale" 
                render={(record, source) => renderCentsCurrency(record[source], record['net-currency'])}/> 
            <DateField locales="it-IT" source="emission-date" label="Emissione"/>
            <DateField locales="it-IT" source="due-date" label="Scadenza"/>
            <DownloadFile format="pdf" />
            <DownloadFile format="xml" label="SDD"/>
            <DownloadFile format="fa" label="FA"/>
            <SendEmail endpoint="email_simple_invoice"/>                         
        </Datagrid>
    </List>
);