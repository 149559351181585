import React from 'react';
import { 
    Filter, 
    TextInput, 
    SelectInput, 
    List, 
    Datagrid, 
    TextField, 
    Create,
    Edit,
    SimpleForm,
    AutocompleteInput,
} from 'react-admin';
import { DateInput } from '../inputs/DateInput';
import PeopleIcon from '@material-ui/icons/People';
import { 
    DefaultBulkActionButtons,
    DefaultPagination,
    selectKind,
    selectCountry,
} from './utils';
export { PeopleIcon as CustomerIcon }

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);


const validator = (values) => {
    const errors = {};
  
    if (values['country']==='Italia') {
      if (!values['iban'] || !values['iban'].length===27) {
        errors['iban'] = ['Deve essere di 27 caratteri'];
      }
      if (!values['vat-number'] || !values['vat-number'].length===16 || !values['vat-number'].length===11) {
        errors['vat-number'] = ['Deve essere di 11 o 16 caratteri'];
      }    
    }
  
    return errors;
  };

export const CustomerCreate = (props) => (
    <Create {...props} >  
      <SimpleForm 
        validate={validator} 
        defaultValue={{
            kind: 'consumer', 
            country: 'Italia'
        }}>
          <SelectInput source="kind" label="Tipo" choices={selectKind}/>   
          <TextInput source="code" label="Codice"/>  
          <TextInput source="name" label="Nome" /> 
          <TextInput source="surname" label="Cognome"/>            
          <AutocompleteInput source="country" label="Paese" choices={selectCountry}/>
          <TextInput source="street-address" label="Indirizzo"/>
          <TextInput source="building-number" label="Nr. civico"/>
          <TextInput source="city" label="Città"/>
          <TextInput source="province" label="Provincia"/>
          <TextInput source="postcode" label="CAP"/>                    
          <TextInput source="vat-number" label="P.IVA/CF"/>
          <TextInput source="iban" label="IBAN"/>
          <TextInput source="email" label="Email"/>
          <TextInput source="phone-number" label="Telefono"/>
          <TextInput source="fax-number" label="FAX"/>
          <TextInput source="umr-name" label="Nome UMR" /> 
          <TextInput source="umr-surname" label="Cognome UMR"/>            
          <TextInput source="umr-vat-number" label="P.IVA/CF UMR" /> 
          <DateInput source="umr-signature-date" label="Data UMR"/>
          <TextInput source="fa-pec" label="PEC FA" /> 
          <TextInput source="fa-codice" label="Codice FA"/>           
      </SimpleForm>
    </Create>
);

export const CustomerEdit = (props) => (
    <Edit 
        {...props}     
        /* disable the app title change when shown */
        title=" "
    >  
      <SimpleForm 
        /* The form must have a name dependent on the record, because by default all forms have the same name */
        form={`customer_edit_${props.id}`}     
        validate={validator}      
        defaultValue={{
            kind: 'consumer', 
            country: 'Italia'
        }}>
          <SelectInput source="kind" label="Tipo" choices={selectKind}/>   
          <TextInput source="code" label="Codice"/>  
          <TextInput source="name" label="Nome" /> 
          <TextInput source="surname" label="Cognome"/>            
          <AutocompleteInput source="country" label="Paese" choices={selectCountry}/>
          <TextInput source="street-address" label="Indirizzo"/>
          <TextInput source="building-number" label="Nr. civico"/>
          <TextInput source="city" label="Città"/>
          <TextInput source="province" label="Provincia"/>
          <TextInput source="postcode" label="CAP"/>                    
          <TextInput source="vat-number" label="P.IVA/CF"/>
          <TextInput source="iban" label="IBAN"/>
          <TextInput source="email" label="Email"/>
          <TextInput source="phone-number" label="Telefono"/>
          <TextInput source="fax-number" label="FAX"/>
          <TextInput source="umr-name" label="Nome UMR" /> 
          <TextInput source="umr-surname" label="Cognome UMR"/>            
          <TextInput source="umr-vat-number" label="P.IVA/CF UMR" /> 
          <DateInput source="umr-signature-date" label="Data UMR"/>
          <TextInput source="fa-pec" label="PEC FA" /> 
          <TextInput source="fa-codice" label="Codice FA"/>          
      </SimpleForm>
    </Edit>
);

export const CustomerList = (props) => (
    <List 
        {...props}
        title="Clienti" 
        bulkActionButtons={<DefaultBulkActionButtons/>}
        perPage={25}
        pagination={<DefaultPagination />}
        filters={< Filters />}
        sort={{ field: 'surname', order: 'ASC' }}>
        <Datagrid expand={<CustomerEdit />}>
            <TextField source="name" label="Nome" />
            <TextField source="surname" label="Cognome" />
            <TextField source="city" label="Città"/> 
        </Datagrid>
    </List>
);
