import React from 'react';
import { 
    Filter, 
    TextInput, 
    SelectInput, 
    List, 
    Datagrid, 
    ChipField, 
    DateField, 
    TextField, 
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
    FunctionField,
    Edit,
    SimpleForm,
    BooleanInput,
    FormDataConsumer,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    NullableBooleanInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { DateInput } from '../inputs/DateInput';
import { 
    selectKind,
    selectPaymentMethods,
    selectInvoicingFrequencies,    
    nameRenderer,
    DefaultBulkActionButtons,
    DefaultPagination,
} from './utils';
import CurrencyInput from '../inputs/CurrencyInput';
import FileInput from '../inputs/FileInput';
import DownloadFile from '../buttons/DownloadFile';
import SendEmail from '../buttons/SendEmail';
import DescriptionIcon from '@material-ui/icons/Description';
export { DescriptionIcon as ContractIcon }

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput 
            label="Cliente" 
            source="customer-id" 
            reference="customers">
            <AutocompleteInput optionText={nameRenderer} />
        </ReferenceInput> 
        <ReferenceInput 
            label="Attivazione" 
            source="activation-id" 
            reference="activations">
            <AutocompleteInput optionText={nameRenderer} />
        </ReferenceInput> 
        <ReferenceInput 
            label="Servizio" 
            source="service-id" 
            reference="services">
            <AutocompleteInput optionText="name" />
        </ReferenceInput> 
        <ReferenceInput 
            label="Servizio Voce" 
            source="voice-service-id" 
            reference="voice-services">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>         
        <ReferenceInput 
            label="Tipologia Installazione" 
            source="installation-id" 
            reference="installations">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>     
        <ReferenceInput 
            label="Promozione" 
            source="promotion-id" 
            reference="promotions">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>                   
        <SelectInput source="kind" label="Tipo" choices={selectKind}  />
        <SelectInput source="invoicing-frequency" label="Fatturazione" choices={selectInvoicingFrequencies}  /> 
        <SelectInput source="payment-method" label="Pagamento" choices={selectPaymentMethods}  /> 
        <NullableBooleanInput label="Terminato" source="is-terminated"  />  
    </Filter>
);

export const ContractEdit = (props) => (
    <Edit
      {...props}
      /* disable the app title change when shown */
      title=" " 
    >
      <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`contract_edit_${props.id}`}       
      >
        <TextInput 
            source="name" 
            label="Nome"/>
        <SelectInput source="payment-method" label="Pagamento" choices={selectPaymentMethods}  /> 
        <BooleanInput 
            label="Terminato" 
            source="is-terminated" />
        <FormDataConsumer>
            {({ formData, ...rest }) => formData['is-terminated'] &&
                <CurrencyInput 
                    label="Costo Terminazione"
                    source="termination_fee_cents" 
                    {...rest}/>
            }
        </FormDataConsumer>       
        <DateField locales="it-IT" source="terminated-at" label="Terminato" />
        <DateField locales="it-IT" source="sent-at" label="Inviato" /> 
        <TextField source="promoter-code" label="Codice Amico" /> 
        <FormDataConsumer>
            {({ formData, ...rest }) => (formData['kind'] === 'consumer') && 
                <ReferenceInput 
                    source="service-id"  
                    label="Servizio Dati" 
                    reference="services" 
                    filter={{ kind: 'consumer' }} 
                    allowEmpty
                    {...rest}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>                    
            }
        </FormDataConsumer>  
        <FormDataConsumer>
            {({ formData, ...rest }) => formData['kind'] === 'consumer' &&
                <ReferenceInput 
                    source="voice-service-id"  
                    label="Servizio Voce" 
                    reference="voice-services" 
                    filter={{ kind: 'consumer' }} 
                    allowEmpty
                    {...rest}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>                    
            }
        </FormDataConsumer> 
        <FormDataConsumer>
            {({ formData, ...rest }) => (formData['kind'] === 'consumer' && formData['voice-service-id'] !== null) &&
                <DateInput 
                    options={{
                        cancelLabel: "Annulla", 
                        format: 'DD/MM/YYYY'
                    }} 
                    source="voice-service-activation-date" 
                    label="Data Attivazione Servizio Voce"
                    {...rest}/>                   
            }
        </FormDataConsumer> 
        <FormDataConsumer>
            {({ formData, ...rest }) => formData['kind'] === 'consumer' &&
                <ReferenceInput 
                    source="promotion-id"  
                    label="Promozione" 
                    reference="promotions" 
                    filter={{ kind: 'consumer' }} 
                    allowEmpty
                    {...rest}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>                    
            }
        </FormDataConsumer>  
        <FormDataConsumer>
            {({ formData, ...rest }) => formData['kind'] === 'consumer' &&
                <ReferenceArrayInput 
                    source="addon-ids"  
                    label="Servizi Aggiuntivi" 
                    reference="addons" 
                    filter={{ kind: 'consumer' }} 
                    allowEmpty
                    {...rest}>
                    <AutocompleteArrayInput optionText="name" />
                </ReferenceArrayInput>                    
            }
        </FormDataConsumer>  
        <FormDataConsumer>
            {({ formData, ...rest }) => formData['kind'] === 'business' &&
                <ReferenceInput 
                    source="service-id"  
                    label="Servizio Dati" 
                    reference="services" 
                    filter={{ kind: 'business' }} 
                    allowEmpty
                    {...rest}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>                    
            }
        </FormDataConsumer>  
        <FormDataConsumer>
            {({ formData, ...rest }) => formData['kind'] === 'business' &&
                <ReferenceInput 
                    source="voice-service-id"  
                    label="Servizio Voce" 
                    reference="voice-services" 
                    filter={{ kind: 'business' }} 
                    allowEmpty
                    {...rest}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>                    
            }
        </FormDataConsumer>  
        <FormDataConsumer>
            {({ formData, ...rest }) => (formData['kind'] === 'business' && formData['voice-service-id'] !== null) &&
                <DateInput 
                    options={{
                        cancelLabel: "Annulla", 
                        format: 'DD/MM/YYYY'
                    }} 
                    source="voice-service-activation-date" 
                    label="Data Attivazione Servizio Voce"
                    {...rest}/>                   
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {({ formData, ...rest }) => formData['kind'] === 'business' &&
                <ReferenceInput 
                    source="promotion-id"  
                    label="Promozione" 
                    reference="promotions" 
                    filter={{ kind: 'business' }} 
                    allowEmpty
                    {...rest}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>                    
            }
        </FormDataConsumer> 
        <FormDataConsumer>
            {({ formData, ...rest }) => formData['kind'] === 'business' &&
                <ReferenceArrayInput 
                    source="addon-ids"  
                    label="Servizi Aggiuntivi" 
                    reference="addons" 
                    filter={{ kind: 'business' }} 
                    allowEmpty
                    {...rest}>
                    <AutocompleteArrayInput optionText="name" />
                </ReferenceArrayInput>                    
            }
        </FormDataConsumer>    
        <RichTextInput source="notes" label="Note" />
        <FileInput source="original-contract" label="Contratto Originale"/>           
      </SimpleForm>
    </Edit>
  );

export const ContractList = (props) => (
    <List 
        {...props}
        title="Contratti" 
        bulkActionButtons={<DefaultBulkActionButtons/>}
        perPage={25}
        pagination={<DefaultPagination />}
        filters={< Filters />}
        sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid expand={<ContractEdit />}>   
            <ReferenceField 
                label="Cliente" 
                source="customer-id" 
                reference="customers"
                linkType={false}
                sortBy="customer.surname">
                <FunctionField label="Name" render={nameRenderer} />
            </ReferenceField> 
            <ReferenceField 
                label="Attivazione" 
                source="activation-id" 
                reference="activations"
                linkType={false}
                sortBy="activation.surname">
                <FunctionField label="Name" render={nameRenderer} />
            </ReferenceField> 
            <ReferenceField 
                label="Servizio Dati" 
                source="service-id" 
                reference="services"
                linkType={false}
                sortBy="service.name">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField 
                source="voice-service-id" 
                allowEmpty 
                label="Servizio Voce" 
                reference="voice-services"
                linkType={false}
                sortBy="voice_service.name">
                <TextField source="name" />
            </ReferenceField> 
            <ReferenceField 
                label="Tip. Inst." 
                source="installation-id" 
                reference="installations"
                linkType={false}
                sortBy="installation.name">
                <TextField source="name" />
            </ReferenceField>   
            <ChipField source="payment-method" label="Pagamento" /> 
            <ChipField source="invoicing-frequency" label="Fatturazione" />           
            <DateField locales="it-IT" source="subscription-date" label="Sottoscritto"/>     
            <DateField locales="it-IT" source="activation-date" label="Attivato"/>            
            <DownloadFile resource="contracts" format="pdf"/> 
            <SendEmail resource="contracts" />        
        </Datagrid>
    </List>
);