import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import inflection from 'inflection'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import classnames from 'classnames'
import {getResources, translate} from 'ra-core'
import DefaultIcon from '@material-ui/icons/ViewList'
import NoteAdd from '@material-ui/icons/NoteAdd'
import {DashboardMenuItem, MenuItemLink, Responsive} from 'react-admin'
import {ReportIcon} from './Report'

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}

const translatedResourceName = (resource, translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label,
          })
        : inflection.humanize(inflection.pluralize(resource.name)),
  })

const MyMenu = ({
  classes,
  className,
  dense,
  hasDashboard,
  onMenuClick,
  open,
  pathname,
  resources,
  translate,
  logout,
  ...rest
}) => (
  <div className={classnames(classes.main, className)} {...rest}>
    {hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
    <MenuItemLink
      to="/reports"
      primaryText="Reports"
      onClick={onMenuClick}
      dense={dense}
      leftIcon={<ReportIcon />}
    />
    <MenuItemLink
      to="/create-contract"
      primaryText="Nuovo Contratto"
      onClick={onMenuClick}
      dense={dense}
      leftIcon={<NoteAdd />}
    />
    {resources
      .filter((r) => r.hasList)
      .map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translatedResourceName(resource, translate)}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          dense={dense}
        />
      ))}
    <Responsive xsmall={logout} medium={null} />
  </div>
)

MyMenu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
  open: PropTypes.bool,
  pathname: PropTypes.string,
  resources: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
}

MyMenu.defaultProps = {
  onMenuClick: () => null,
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  resources: getResources(state),
  pathname: state.router.location ? state.router.location.pathname : '/', // used to force redraw on navigation
})

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
    {}, // Avoid connect passing dispatch in props,
    null,
    {
      areStatePropsEqual: (prev, next) =>
        prev.resources.every(
          (value, index) => value === next.resources[index], // shallow compare resources
        ) &&
        prev.pathname === next.pathname &&
        prev.open === next.open,
    },
  ),
  withStyles(styles),
)

export default enhance(MyMenu)

// const MyMenu = ({ resources, onMenuClick, logout }) => (
//     <div>
//         {resources.map(resource => (
//             <MenuItemLink to={`/${resource.name}`} primaryText={resource.name} onClick={onMenuClick} />
//         ))}
//         <MenuItemLink to="/reports" primaryText="Reports" onClick={onMenuClick} />
//         <Responsive
//             small={logout}
//             medium={null} // Pass null to render nothing on larger devices
//         />
//     </div>
// );

// const mapStateToProps = state => ({
//     resources: getResources(state),
// });

// export default withRouter(connect(mapStateToProps)(MyMenu));
